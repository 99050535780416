var base_url = $('meta[name="base_url"]').attr('content');

$('.btn').button();
$("[rel='tooltip']").tooltip();

$(document).ready(
    function () {

        $('html').niceScroll({
            cursorcolor: "#000",
            cursorborder: "0px solid #fff",
            railpadding: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            },
            cursorwidth: "10px",
            cursorborderradius: "0px",
            cursoropacitymin: 0.2,
            cursoropacitymax: 0.8,
            boxzoom: true,
            horizrailenabled: false,
            zindex: 9999
        });

        // footer always on bottom
        var docHeight = $(window).height();
        var footerHeight = $('#main-footer').height();
        var footerTop = $('#main-footer').position().top + footerHeight;

        if (footerTop < docHeight) {
            $('#main-footer').css('margin-top', (docHeight - footerTop) + 'px');
        }
    }
);

$(function () {
    $('img').addClass('img-responsive');

    $('iframe[src*="youtube.com"]').each(function () {
        if (!$(this).hasClass('embed-responsive-item')) {
            $(this).wrap("<div class='row'><div class='col-sm-6 padding-left-0'><div class='embed-responsive embed-responsive-16by9'></div></div></div>");
            $(this).addClass("embed-responsive-item");
        }
    });

    WebFont.load({
        google: {
            families: ['Open+Sans:400,700:latin-ext']
        }
    });

    $("body").floatingSocialShare({
        buttons: ["facebook", "twitter", "google-plus"],
        text: ""
    });

    $('img.chosen-one').click(function () {
        $('input[name="language"]').val($(this).attr("alt"));
        $('#anakin-skywalker').submit();
    });
});
